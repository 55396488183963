import React, { useState } from 'react';

import {
  Image,
  ImageProps,
  keyframes,
  Skeleton,
  SkeletonProps,
  useTheme,
} from '@chakra-ui/react';

const shimmerEffect = keyframes`
  100% {-webkit-mask-position:left}
`;

export type ShimmerImageProps = ImageProps & {
  skeletonProps?: SkeletonProps;
  isShimmerNeeded?: boolean;
};

const ShimmerImage: React.FC<ShimmerImageProps> = ({
  skeletonProps,
  isShimmerNeeded = false,
  ...imageProps
}) => {
  const theme = useTheme();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  return (
    <Skeleton isLoaded={isLoaded} {...skeletonProps}>
      <Image
        alt=''
        background={theme.colors.defaultGray}
        onLoad={() => setIsLoaded(true)}
        sx={
          isShimmerNeeded
            ? {
                WebkitMask:
                  'linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/400% 100%',
                backgroundRepeat: 'no-repeat',
                animation: `${shimmerEffect} 2.5s`,
                animationFillMode: 'forwards',
              }
            : {}
        }
        {...imageProps}
      />
    </Skeleton>
  );
};

export default ShimmerImage;
