import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { SearchParamsEnum } from '@/enum/searchParams.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

export const useLangDetection = () => {
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setItem: setLang } = useLocalStorage<string>(
    StorageKeysEnum.i18nextLng,
  );

  const possibleLangCodesMap = {
    lt: 'lv',
    es: 'et',
    rus: 'ru',
  };

  const paramLang = searchParams.get(SearchParamsEnum.lang);

  const lang = Object.entries(possibleLangCodesMap).reduce(
    (paramLang, [code, possibleCode]) =>
      paramLang === possibleCode ? code : paramLang,
    paramLang,
  );

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang, () => setLang(lang));
      searchParams.delete(SearchParamsEnum.lang);
      setSearchParams(searchParams);
    }
  }, [i18n, lang, searchParams, setLang, setSearchParams]);
};
