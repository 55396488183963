import React from 'react';

import { Button, ButtonProps } from '@chakra-ui/react';

const ArrowButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <Button
      _hover={{
        svg: {
          transform: rest.leftIcon
            ? 'translateX(-5px)'
            : rest.rightIcon
            ? 'translateX(5px)'
            : 'translateX(0)',
        },
      }}
      alignItems='center'
      border='0'
      cursor='pointer'
      display='flex'
      outline='0'
      sx={{
        svg: {
          transition: 'transform 0.2s ease-out',
        },
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ArrowButton;
