const shadows = {
  buttonShadow: '0px 0px 0px #00000',
  cardShadow: '0px 3.4375px 34.375px 0px rgba(255, 255, 255, 0.10)',
  contestCardShadow: '0px 7.03443px 7.03443px rgba(0, 0, 0, 0.25)',
  formShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 25px',
  selectShadow: '3px 3px 10px 6px rgba(0, 0, 0, 0.06)',
  navbarShadow: '0px 4px 16px 0px rgba(255, 255, 255, 0.15)',
};

export default shadows;
