import React, { createContext } from 'react';

import { useGetUserProfileQuery } from '@/services/api.service';

import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import { UserProfileType } from '@/types/domain';

type UserContextType = {
  user: UserProfileType | undefined;
  isLoading: boolean;
};

const UserContext = createContext<UserContextType | null>(null);

const UserContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { value: jwt } = useLocalStorage<string>(StorageKeysEnum.jwt);

  const { data, isLoading } = useGetUserProfileQuery(undefined, {
    skip: !jwt,
  });

  return (
    <UserContext.Provider value={{ user: data?.user, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};

function useUserContext(): UserContextType {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return context;
}

export { UserContextProvider, useUserContext };
