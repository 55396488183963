import { Route, Routes } from 'react-router-dom';

import './App.css';

import AppLayout from '@/AppLayout';

import { AppRoutes } from './routes';

function App() {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        {AppRoutes.map((route) => (
          <Route element={route.element} key={route.path} path={route.path} />
        ))}
      </Route>
    </Routes>
  );
}
export default App;
