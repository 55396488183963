import { LanguageCodeType } from '@/localization';

import EST from '@/assets/icons/countries/EST.svg';
import FRN from '@/assets/icons/countries/FRN.png';
import GBR from '@/assets/icons/countries/GBR.svg';
import LAT from '@/assets/icons/countries/LAT.svg';
import RUS from '@/assets/icons/countries/RUS.png';
import SVK from '@/assets/icons/countries/SVK.svg';

export const countryFlagByCode = (code: LanguageCodeType) => {
  switch (code.toLowerCase()) {
    case 'es':
      return EST;
    case 'lt':
      return LAT;
    case 'sl':
      return SVK;
    case 'rus':
      return RUS;
    case 'fr':
      return FRN;
    case 'en':
    default:
      return GBR;
  }
};
